import {
  ContentContainer,
  Footer,
  Heading,
  PageContainer,
  Stack,
  StyledPrimaryButton,
  StyledSecondaryButton,
  Text,
  Threshold,
} from 'fitify-ui-onboarding/src/components'
import NativeImage from 'fitify-ui-onboarding/src/components/Image/NativeImage'
import { useUserContext } from 'fitify-ui-onboarding/src/contexts/UserContext'
import { useRouter } from 'fitify-ui-onboarding/src/hooks/useRouter'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthSignupDisclaimer } from 'sections/auth/AuthSignupDisclaimer'
import { OnboardingImageData, getImageProps } from 'utils/images'
import { ONBOARDING_PAGES } from 'utils/routes'

import { HomePageProps } from './types'

export default function Page({
  t,
  actions,
  initialLocale,
  characterStyle,
}: HomePageProps) {
  const { isLoggedIn } = useUserContext()
  const { i18n } = useTranslation()
  const router = useRouter()

  const imageProps = useMemo<OnboardingImageData>(() => {
    let imageKey:
      | 'youngCoupleCroppedLocalized'
      | 'youngCoupleMemojiLocalized'
      | 'youngCoupleRealisticLocalized'

    switch (characterStyle) {
      case 'memoji':
        imageKey = 'youngCoupleMemojiLocalized'
        break
      case 'realistic':
        imageKey = 'youngCoupleRealisticLocalized'
        break
      case 'illustrated':
      default:
        imageKey = 'youngCoupleCroppedLocalized'
    }

    const imageProps = getImageProps(
      'home',
      imageKey,
      initialLocale || i18n.language
    ) as OnboardingImageData
    return imageProps
  }, [characterStyle, i18n.language, initialLocale])

  const isFooterShown = router.route === ONBOARDING_PAGES.home.path

  return (
    <PageContainer>
      <ContentContainer xs="1.5rem" md="2rem" removePadding>
        <Stack xs="0.5rem">
          <Heading>{t('welcome_title')}</Heading>
          <Text color="blue75">{t('welcome_subtitle')}</Text>
        </Stack>

        <NativeImage
          {...imageProps}
          alt="Homepage couple"
          isResponsive
          wrapperStyles={{ flex: '1' }}
          style={{
            backgroundColor: 'var(--c-blue-base)',
            borderRadius: '16px',
          }}
        />
        <Threshold hasBackground={false}>
          <Stack xs={20} md={24} align="center">
            <StyledPrimaryButton onClick={actions.handleContinue}>
              {t('btn_continue')}
            </StyledPrimaryButton>

            {!isLoggedIn && (
              <Stack xs={16} align="center">
                <Text color="blue75">{t('welcome_have_account_question')}</Text>
                <StyledSecondaryButton size="md" onClick={actions.handleLogin}>
                  {t('email_login_forgot_btn_sign_in')}
                </StyledSecondaryButton>
              </Stack>
            )}
            {isFooterShown && <Footer disclamer={<AuthSignupDisclaimer />} />}
          </Stack>
        </Threshold>
      </ContentContainer>
    </PageContainer>
  )
}
