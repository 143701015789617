import { useTranslation } from 'next-i18next'

import { footerLinks } from 'config/navigation'

export function AuthSignupDisclaimer() {
  const { t } = useTranslation()

  return (
    <span>
      {t('login_terms_agreement', { 0: '{{0}}', 1: '{{1}}' })
        .split(/(\{\{\d*\}\})/)
        .map((item) => {
          const match = item.match(/{{(\d*)}}/)
          if (match) {
            const index = Number.parseInt(match[1], 10)
            if (footerLinks[index]) {
              return (
                <a
                  key={index}
                  href={footerLinks[index].href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(footerLinks[index].translate)}
                </a>
              )
            }
          }
          return item
        })}
    </span>
  )
}
