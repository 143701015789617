export const footerLinks: {
  href: string
  translate: string
}[] = [
  {
    href: 'https://gofitify.com/privacy-policy.html',
    translate: 'login_privacy',
  },
  {
    href: 'https://gofitify.com/terms.html',
    translate: 'login_terms',
  },
]
